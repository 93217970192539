import { useGSAP } from "@gsap/react"
import gsap from "gsap"
import { rightImg, zplIconMisli } from "../utils"
import VideoCarousel from './VideoCarousel';

const Highlights = () => {
  useGSAP(() => {
    gsap.to('#title', { opacity: 1, y: 0 })
    gsap.to('.link', { opacity: 1, y: 0, duration: 1, stagger: 0.25 })
  }, [])

  return (
    <section id="highlights" className="w-screen overflow-hidden h-full common-padding bg-zinc">
      <div className="screen-max-width">
        <div className="mb-12 w-full md:flex items-center justify-between">
          <h1 id="title" className="section-heading color-customOrange">TOPUN HARA DÜŞƏCƏYİNİ TAP,<br /> SƏN DƏ QAZAN!</h1>
          <div className="flex justify-center gap-5 mt-10">
              <a 
              className="px-5 py-2 border-6 rounded-md flex items-center justify-center bg-customOrange text-white font-bold 
              hover:bg-customWhite hover:text-customOrange transition duration-300 ease-in-out"
              href="https://www.misli.az/epoz-qazan?utm_source=smartbee&utm_medium=landing-page-plinko&utm_campaign=plinko">
              İNDİ OYNA
             </a>
          </div>
        </div>

        <VideoCarousel />
        <div className="flex justify-center gap-5 mt-10">
              <a 
              className="px-5 py-2 border-6 rounded-md flex items-center justify-center bg-customOrange text-white font-bold 
              hover:bg-customWhite hover:text-customOrange transition duration-300 ease-in-out"
              href="https://www.misli.az/epoz-qazan?utm_source=smartbee&utm_medium=landing-page-plinko&utm_campaign=plinko">
              İNDİ OYNA
             </a>
          </div>
      </div>
      
    </section>
  )
}

export default Highlights