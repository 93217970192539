import React from 'react';

const Navbar = () => {
  return (
    <header className="w-full py-5 sm:px-10 px-10 flex justify-between items-center bg-black">
      <nav className="flex justify-between items-center w-full screen-max-width cursor-pointer">
        <img src='https://www.misli.az/_nuxt/img/logo.ae7163a.svg' alt="Misli Icon" className="w-auto h-10" />

        <a 
          className="px-5 py-2 border-6 rounded-md flex items-center justify-center bg-customOrange text-white font-bold 
          hover:bg-customWhite hover:text-customOrange transition duration-100 ease-in-out sm:block hidden"
          href="https://www.misli.az/epoz-qazan?utm_source=smartbee&utm_medium=landing-page-plinko&utm_campaign=plinko">
          QEYDİYYAT
        </a>
      </nav>
    </header>
  );
}

export default Navbar;
